import * as React from "react";
import { createRef } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
// Simple footer component

// table object properties
export interface IVipStringListProps {
  field: string;
  value: string[];
  placeholder: string;
  text: string;
  options?: any[];
  targetField?: string;
  labelKey?: string;
  updateField: (field: any, payload: any) => void;
  sortFunction?: (a: string, b: string) => number;
  additionalButtonText?: string;
  additionalButtonId?: string;
  additionalButtonVariant?: string;
  additionalButtonFunction?: (emails:string[]) => void;
}


//
export interface IVipStringListState {
  list: string[];
  newValue: string;
}

const updateState =
  <T extends string>(key: keyof any, value: T) =>
    (prevState: any): any => ({
      ...prevState,
      [key]: value,
    });

export default class VipStringList extends React.Component<
  IVipStringListProps,
  IVipStringListState
> {
  constructor(props: any) {
    super(props);
    let xxy: string[];
    if (this.props.value === undefined) {
      xxy = [];
    } else {
      xxy = this.props.value;
    }
    this.state = { list: xxy, newValue: "" };

    this.remove.bind(this);
    this.add.bind(this);
    this.addStr.bind(this);
  }
  public handleChange = (event: any) => {
    this.setState(updateState(event.target.id.toString(), event.target.value));
  };

  updateFieldWithValue = (list: string[]) => {
    this.props.updateField(this.props.field, list);
  };
  add = () => {
    if(this.state.newValue){
      const { list, newValue } = this.state;
      list.push(newValue);
      this.setState({ newValue: "" });
      this.props.updateField(this.props.field, [...list]);
    }
  };

  addStr = (obj: any) => {
    if (this.props.targetField !== undefined) {
      this.setState({ newValue: obj[this.props.targetField] }, () => {
        this.add();
        this.setState({ newValue: "" });
      });
    }
  };
  remove = (str: string) => {

    const { list } = this.state;

    const index = list.indexOf(str);

    // console.log(index);

    list.splice(index, 1);



    this.props.updateField(this.props.field, [...list]);

    this.setState({

      newValue: this.state.newValue,

    });

  };

  private inputEl: React.RefObject<Typeahead<any>> = createRef();
  public render() {
    const { newValue } = this.state;
    let list = this.state.list;
    if (this.props.sortFunction) {
      list.sort(this.props.sortFunction);
    }
    const { options } = this.props;
    //  console.log(options)
    let actOptions: any[] = [];
    if (options !== undefined) {
      actOptions = options;
    }

    actOptions = actOptions.filter((joyExt: any) => {
      if (this.props.targetField !== undefined) {
        const value = joyExt[this.props.targetField];
        if (list.indexOf(value) >= 0) {
          return undefined;
        } else {
          return joyExt;
        }
      } else {
        return undefined;
      }
    });
    if (actOptions === undefined) {
      actOptions = [];
    }
    // actOptions = [];
    return (
      <div>
        {list.map((string: string) => {
          return (
            <p key={string} style={{ margin: "0.5rem" }}>
              {string}{" "}
              <Button
                onClick={(event: any) => {
                  event.preventDefault();
                  this.remove(string);
                }}
                variant="outline-secondary"
              >
                Remove
              </Button>
            </p>
          );
        })}

        <br />

        <InputGroup
          style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}
        >
          <InputGroup.Prepend>
            <span className="input-group-text">{this.props.text}</span>
          </InputGroup.Prepend>
          {options === undefined ? (
            <FormControl
              id="newValue"
              value={newValue}
              onChange={this.handleChange}
              aria-label="Text input with radio button"
            />
          ) : (
            <Typeahead
              id="area"
              placeholder={this.props.placeholder}
              ref={this.inputEl}
              onChange={(selected: any[]) => {
                console.log(selected);
                const inp = selected[0];
                this.addStr(inp);
                const moo: any = this.inputEl.current;
                if (moo !== null) {
                  moo.getInstance().clear();
                }
              }}
              labelKey="name"
              options={actOptions}
            />
          )}

          <InputGroup.Append>
            <Button
              onClick={(event: any) => {
                event.preventDefault();
                this.add();
              }}
              variant="outline-secondary"
            >
              Add
            </Button>
            {this.props.additionalButtonText !== undefined && <Button
              id={(this.props.additionalButtonId ? this.props.additionalButtonId : '')}
              //  style={{ marginLeft: "30px !important" }}
              // ref={(el: any) => el && el.style.setProperty("marginLeft", "30px", "important")}
              onClick={(event: any) => {
                event.preventDefault();
                this.add();
                this.props.additionalButtonFunction && this.props.additionalButtonFunction(this.state.list);
              }}
              variant={(this.props.additionalButtonVariant ? this.props.additionalButtonVariant : "outline-secondary")}
            >
              {this.props.additionalButtonText}
            </Button>}
          </InputGroup.Append>
        </InputGroup>
      </div>
    );
  }
}
